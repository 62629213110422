import { useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import useOutsideClick from "../hooks/useClickOutside";

export type SimpleDropdownOption = {
    id: string;
    label: string;
}

export type SimpleDropdownProps = {
    className?: string;
    options: SimpleDropdownOption[];
    defaultValue?: SimpleDropdownOption;
    onChange?: (newValue: SimpleDropdownOption) => void;
}

const SimpleDropdown = ({ className, defaultValue, options, onChange }: SimpleDropdownProps) => {
    const [selectedOption, setSelectedOption] = useState<SimpleDropdownOption>(defaultValue || options[0])
    const [dropdownActive, setDropdownActive] = useState(false);

    // clicking outside of dropdown menu should de-activate it
    const dropdownRef = useRef<HTMLDivElement>(null);
    useOutsideClick({
        ref: dropdownRef,
        handler: () => setDropdownActive(false),
    });

    const selectOption = (newSelectedOption: SimpleDropdownOption) => {
        setSelectedOption(newSelectedOption);
        setDropdownActive(false);
        if (onChange) {
            onChange(newSelectedOption);
        }
    }

    return (
        <div ref={dropdownRef} className={`${className} roka-simple-dropdown`}>
            <div className={`roka-dropdown-button ${dropdownActive && 'active'}`} onClick={() => setDropdownActive(!dropdownActive)}>
                <FaChevronDown />
                {selectedOption.label}
            </div>
            <ul className={`roka-dropdown-options ${dropdownActive && 'active'}`}>
                {options.map((option) => (
                    <li key={option.id} onClick={() => selectOption(option)}>
                        {option.label}
                    </li>
                ))}
            </ul>
        </div>
    )
};

export default SimpleDropdown;