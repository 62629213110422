type ButtonProps = {
  onClick?: () => void;
  children?: React.ReactNode;
  buttonStyle?: "flat" | "outline";
  disabled?: boolean;
};

const Button = (props: ButtonProps) => (
  <button
    disabled={props.disabled}
    className={`roka-btn ${props.buttonStyle || "flat"}`}
    onClick={props.onClick}
  >
    {props.children}
  </button>
);

export default Button;
