
type CardProps = {
    className?: string;
    children?: React.ReactNode;
}

const Card = (props: CardProps) => (
    <div className={`roka-card ${props.className}`}>{props.children}</div>
);

export default Card;