import { useRef, useState } from "react";
import { FaBars } from "react-icons/fa";
import { FaGear, FaRightFromBracket } from "react-icons/fa6";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import useOutsideClick from "../hooks/useClickOutside";
import rokaLogo from "../images/roka-management-logo.png";

export const ProtectedLayout = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);

  // clicking outside of dropdown menu should de-activate it
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsSettingsOpen(false),
  });

  if (!user) {
    return <Navigate to="/login" />;
  }

  const goToSettings = () => {
    setIsSettingsOpen(false);
    navigate("/settings");
  };

  return (
    <div className="protected-layout">
      <nav>
        <img
          onClick={() => navigate("/")}
          className="roka-logo"
          src={rokaLogo}
          alt="roka-management-logo"
        />
        <div className="location-name">{`| ${user.name}`}</div>
        <div ref={dropdownRef} className="settings">
          <div
            onClick={() => setIsSettingsOpen(!isSettingsOpen)}
            className={`toggle-settings-button ${isSettingsOpen && "active"}`}
          >
            <FaBars />
          </div>
          <div
            className={`settings-dropdown-items ${isSettingsOpen && "active"}`}
          >
            <div className="setting-item" onClick={logout}>
              <FaRightFromBracket /> Logg ut
            </div>
            <div className="setting-item" onClick={goToSettings}>
              <FaGear /> Innstillinger
            </div>
          </div>
        </div>
      </nav>
      <Outlet />
    </div>
  );
};
