import { DateInput3 } from "@blueprintjs/datetime2";
import { ChartData } from "chart.js";
import {
  formatISO,
  parse,
  startOfToday,
  startOfYesterday,
  subHours,
  subMonths,
  subWeeks,
} from "date-fns";
import { find } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { ChartDataV2 } from "../../api/chartData";
import { primaryUIColor, secondaryUIColor } from "../../colors";
import Card from "../../components/Card";
import SimpleDropdown, {
  SimpleDropdownOption,
} from "../../components/SimpleDropdown";
import LineChart from "../../components/charts/LineChart";
import { useAuth } from "../../hooks/useAuth";
import { useRokaApi } from "../../hooks/useRokaApi";

const DATE_TIME_FORMAT = "dd.MM.yyyy HH:mm";

type ChartState = {
  startTime: Date;
};

type ChartParamOption = SimpleDropdownOption & ChartState;

const chartParamOptions: ChartParamOption[] = [
  {
    id: "last-hour",
    label: "Foregående time",
    startTime: subHours(new Date(), 1),
  },
  { id: "yesterday", label: "I går", startTime: startOfYesterday() },
  {
    id: "1-week-ago",
    label: "1 uke siden",
    startTime: subWeeks(startOfToday(), 1),
  },
  {
    id: "1-month-ago",
    label: "1 måned siden",
    startTime: subMonths(startOfToday(), 1),
  },
];

type PeriodOption = SimpleDropdownOption & {
  id: "hour" | "day";
};
const periodOptions: PeriodOption[] = [
  { id: "hour", label: "1 time" },
  { id: "day", label: "24 timer" },
];

type DateAndTime = { date: Date; time: Date };
const mergeDateAndTime = ({ date, time }: DateAndTime): Date => {
  let mergedDateTime = new Date(date);
  mergedDateTime.setHours(time.getHours());
  mergedDateTime.setMinutes(time.getMinutes());
  return mergedDateTime;
};

const LineChartDashboard = () => {
  const [chartData, setChartData] = useState<ChartDataV2 | undefined>();
  const [startTime, setStartTime] = useState<Date>(
    chartParamOptions[0].startTime
  );
  const [period, setPeriod] = useState<"hour" | "day">("hour");
  const {
    user: { name },
  } = useAuth();
  const { fetchChartDataV2 } = useRokaApi();

  const refreshChartData = useCallback(async () => {
    // Update chartData when startTime changes
    if (name) {
      try {
        const newChartData = await fetchChartDataV2(name, startTime, period);
        setChartData(newChartData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, [name, startTime, period, fetchChartDataV2]);

  useEffect(() => {
    refreshChartData();
  }, [refreshChartData]);

  const handleTimeChange = (newTime: Date) => {
    console.log("Handle time change", newTime);
    setStartTime((prevTime) =>
      mergeDateAndTime({ date: prevTime, time: newTime })
    );
  };

  const handleDateChange = (newDate: string | null) => {
    console.log("Handle date change", newDate);
    if (newDate) {
      let newDateValue = parse(newDate, "yyyy-MM-dd", new Date());
      setStartTime((prevTime) =>
        mergeDateAndTime({ date: newDateValue, time: prevTime })
      );
    }
  };

  const handleDropdownSelect = ({ id }: SimpleDropdownOption) => {
    const selectedChartParamOption = find(chartParamOptions, ["id", id]);
    if (selectedChartParamOption) {
      setStartTime(selectedChartParamOption.startTime);
    }
  };

  const handlePeriodChange = ({ id }: SimpleDropdownOption) => {
    const selectedPeriodOption = find(periodOptions, ["id", id]);
    if (selectedPeriodOption) {
      setPeriod(selectedPeriodOption.id);
    }
  };

  if (chartData === undefined) {
    return <div>Loading ...</div>;
  }

  const { labels, data } = chartData;

  const chartJSData: ChartData<
    "line",
    (number | [number, number] | null)[],
    unknown
  > = {
    labels,
    datasets: [
      {
        label: "Antall inne",
        data,
        backgroundColor: primaryUIColor,
        borderColor: secondaryUIColor,
        borderWidth: 2,
        pointRadius: 0,
      },
    ],
  };

  // DateInput stuff
  const dateInputValue = formatISO(startTime);
  const dateTimePickerValue = startTime;

  return (
    <div className="line-chart-dashboard">
      <Card className="chart">
        <div className="header">
          <div className="left">
            <h1>Antall inne</h1>
            <p>
              Minutt for minutt over en time eller hvert 10. minutt over 24
              timer.
            </p>
          </div>
          <div className="right">
            <SimpleDropdown
              className="preset-dropdown"
              options={chartParamOptions}
              onChange={handleDropdownSelect}
            />
            <DateInput3
              className="date-time-picker"
              dateFnsFormat={DATE_TIME_FORMAT}
              value={dateInputValue}
              placeholder="..."
              onChange={handleDateChange}
              closeOnSelection={false}
              timePickerProps={{
                precision: "minute",
                onChange: handleTimeChange,
                value: dateTimePickerValue,
                showArrowButtons: true,
              }}
            />
            <SimpleDropdown
              className="preset-dropdown"
              options={periodOptions}
              onChange={handlePeriodChange}
            />
          </div>
        </div>
        <LineChart data={chartJSData} />
      </Card>
    </div>
  );
};

export default LineChartDashboard;
