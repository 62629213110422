const zinc = {
  50: "#fafafa",
  100: "#f4f4f5",
  200: "#e4e4e7",
  300: "#d4d4d8",
  400: "#a1a1aa",
  500: "#71717a",
  600: "#52525b",
  700: "#3f3f46",
  800: "#27272a",
  900: "#18181b",
  950: "#09090b",
};

// Background
export const backgroundColor = "#0E0E12";

// UI Colors
export const primaryUIColor = zinc[500];
export const secondaryUIColor = zinc[300];

// Banners
export const bannerDarkBackground = zinc[950];
export const bannerDarkIconPrimary = zinc[400];
export const bannerDarkIconSecondary = zinc[900];

export const bannerLightBackground = zinc[950];
export const bannerLightIconPrimary = zinc[300];
export const bannerLightIconSecondary = zinc[900];

// Text
export const textColorLightPrimary = zinc[50];
export const textColorLightSecondary = zinc[100];

export const textColorDarkPrimary = zinc[950];
export const textColorDarkSecondary = zinc[600];

// Buttons
export const btnFlatDefaultBgColor = zinc[500];
export const btnFlatHoverBgColor = zinc[600];
export const btnFlatActiveBgColor = zinc[700];
export const btnFlatDisabledBgColor = zinc[950];
export const btnFlatDisabledTextColor = zinc[700];

export const btnOutlineDefaultColor = zinc[600];
export const btnOutlineHoverColor = zinc[700];
export const btnOutlineActiveColor = zinc[800];
export const btnOutlineDisabledColor = zinc[900];

// Cards
export const cardDarkBackground = "#18181B";
export const cardDarkBorderColor = "#27272a";
