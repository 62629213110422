
import { find } from "lodash";
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { FlamingoAttribution } from "../components/FlamingoAttribution";
import SimpleDropdown, { SimpleDropdownOption } from "../components/SimpleDropdown";
import LineChartDashboard from "./dashboards/LineChartDashboard";
import LiveMetricsDashboard from "./dashboards/LiveMetricsDashboard";
import { dashboards } from './dashboards/dashboards';

const Main = () => {
    const { dashboardId } = useParams();
    const navigate = useNavigate();

    // if there's no dashboard matching path params, redirect to live-data dashboard by default
    const selectedDashboard = find(dashboards, ["id", dashboardId]);
    if (!selectedDashboard) {
        return <Navigate to="/live-data" />
    }

    const dropdownOptions: SimpleDropdownOption[] = dashboards.map(({ id, name }) => ({ id, label: name }));
    const handleDropdownSelect = (selectedOption: SimpleDropdownOption) => {
        // Redirect to dashboard uri
        navigate(`/${selectedOption.id}`);
    }
    const selectedDropdownOption = find(dropdownOptions, ["id", dashboardId]);

    // add any new dashboards here (must also be in dashboards.ts)
    let dashboardRenderable;
    switch (selectedDashboard.id) {
        case "live-data":
            dashboardRenderable = <LiveMetricsDashboard />;
            break;
        case "historical-data":
            dashboardRenderable = <LineChartDashboard />;
            break;
        default:
            dashboardRenderable = <div>Not implemented!</div>;
    }

    return (
        <div className="main">
            <SimpleDropdown
                className="dashboard-select"
                options={dropdownOptions}
                defaultValue={selectedDropdownOption}
                onChange={handleDropdownSelect}
            />

            {dashboardRenderable}
            <FlamingoAttribution />
        </div>
    );
}

export default Main;
