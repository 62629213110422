import { format, parse } from "date-fns";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Card from "../components/Card";
import { useRokaApi } from "../hooks/useRokaApi";

const DEFAULT_RESET_TIME = "04:00";

const Settings = () => {
  const [resetTime, setResetTime] = useState<string | undefined>();
  const navigate = useNavigate();
  const { fetchState, updateState } = useRokaApi();

  useEffect(() => {
    fetchState()
      .then((rokaState) => {
        const initialResetState = format(rokaState.resetTime, "HH:mm");
        setResetTime(initialResetState);
      })
      .catch((error) => {
        console.error("Error fetching state:", error);
      });
  }, [fetchState]);

  const handleResetTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newResetTime = e.target.value;
    setResetTime(newResetTime);
    updateState({ resetTime: parse(newResetTime, "HH:mm", new Date()) });
  };

  const resetResetTime = () => {
    setResetTime(DEFAULT_RESET_TIME);
    updateState({
      resetTime: parse(DEFAULT_RESET_TIME, "HH:mm", new Date()),
    });
  };

  if (resetTime == null) {
    return <div>Laster inn ...</div>;
  }

  return (
    <div className="settings-view">
      <div className="wrapper">
        <h1>Innstillinger</h1>

        <Card>
          <div className="setting reset-time">
            <h4 className="label">Reset tid</h4>
            <p className="description">
              Velg tid på døgnet for når telling "resettes". Dette påvirker
              hvordan "max i dag" og "endring siste timen" beregnes. Standard
              innstilling er "04:00".
            </p>
            <div className="form">
              <input
                type="time"
                name="reset-time"
                value={resetTime}
                onChange={handleResetTimeChange}
              />
              <Button buttonStyle="outline" onClick={resetResetTime}>
                Tilbakestill
              </Button>
            </div>
          </div>
        </Card>
      </div>

      <div className="go-back">
        <Button onClick={() => navigate(-1)}>Gå tilbake</Button>
      </div>
    </div>
  );
};

export default Settings;
