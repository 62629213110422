import { Navigate, Route, Routes } from "react-router-dom";
import { ProtectedLayout } from "./components/ProtectedLayout";
import { AuthProvider } from "./hooks/useAuth";
import Login from "./views/Login";
import Main from "./views/Main";
import Settings from "./views/Settings";

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedLayout />}>
          <Route path=":dashboardId" element={<Main />} />
          <Route path="/settings" element={<Settings />} />
          <Route index element={<Navigate to="/live-data" />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
};

export default App;
