import flamingoLogo from '../images/flamingo-logo.png';

export const FlamingoAttribution = () => {
    return (
        <div className="flamingo-attribution">
            <div className="wrapper">
                <img src={flamingoLogo} alt="flamingo-logo" />
                <span>
                    Levert av Flamingo Solutions
                </span>
            </div>
        </div>
    )
}